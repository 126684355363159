export const environment = {
  production: false,
  panel: "parkerexch_",
  // domain: "jarvisexch.com",
  // baseUrl: "https://jarvisexch.com",
  domain: "2023exch.com",
  baseUrl: "https://api.2023exch.com",
  // domain: "parker777.io",
  // baseUrl: "https://api.parker777.io",
  pollers: {
      oddsData: 600,
      listOddsData: 10000,
      scoresData: 1000,
  },
  isMobile: true,
  noDataMsg: 'No Data Found',
  wpLinkShowPages: '/sports, /dashboard, /home, /casino',
  demoLoginUserName: 'demo123',
  imgPrefix: '/parkerexch_client',
  guestEnable: false,
  darkMode: false,
  horseRaceJockeyImgPrefix: 'https://content-cache.cdnbf.net/feeds_images/Horses/SilkColours/',
  stakes: { "stake_name_1": "100", "stake_name_2": "5000", "stake_name_3": "500", "stake_name_4": "25000", "stake_name_5": "50000", "stake_name_6": "100000", "stake_name_7": "500000", "stake_name_8": "1000000", "stake_amount_1": 100, "stake_amount_2": 5000, "stake_amount_3": 500, "stake_amount_4": 25000, "stake_amount_5": 50000, "stake_amount_6": 100000, "stake_amount_7": 500000, "stake_amount_8": 1000000 },
  desktopBannerList: ['https://static-content-banners.s3.ap-south-1.amazonaws.com/parker/desktop/bnr1-min.png', 'https://static-content-banners.s3.ap-south-1.amazonaws.com/parker/desktop/bnr2-min.png', 'https://static-content-banners.s3.ap-south-1.amazonaws.com/parker/desktop/bnr3-min.png', 'https://static-content-banners.s3.ap-south-1.amazonaws.com/parker/desktop/bnr4-min.png', 'https://static-content-banners.s3.ap-south-1.amazonaws.com/parker/desktop/bnr5-min.png'],
  mobileBannerList: ['https://static-content-banners.s3.ap-south-1.amazonaws.com/parker/mobile/bnr1-min.png', 'https://static-content-banners.s3.ap-south-1.amazonaws.com/parker/mobile/bnr2-min.png', 'https://static-content-banners.s3.ap-south-1.amazonaws.com/parker/mobile/bnr3-min.png', 'https://static-content-banners.s3.ap-south-1.amazonaws.com/parker/mobile/bnr4-min.png', 'https://static-content-banners.s3.ap-south-1.amazonaws.com/parker/mobile/bnr5-min.png'],
  host: {
      domain: '',
      name: '',
      dName: '',
      ext: '',
      theme: '',
      email: '',
      pusher: {
          key: '',
          cluster: ''
      },
      themePath: [''],
      guestEnable: false,
      ftLivetvData: 'https://e765432.xyz/static/cc6c487c44b971286c3d0d1e24649619b4681674/getdata.php?chid=',
      afLoginRedirectUrl: '',
      newHomePage: false,
      isCustomBanner: false,
      demoLogin: false,
      downloadApk: '',
      whatsapp: '',
  },
  hosts: [
      {
          domain: 'localhost',
          name: 'localhost',
          dName: 'LocalHost',
          ext: '.com',
          theme: 'blue-body',
          email: '',
          pusher: {
              key: 'c8bbdb36496c73b41567',
              cluster: 'eu'
          },
          themePath: ['assets/css/theme-files/theme-master.css'],
          guestEnable: true,
          ftLivetvData: 'https://e765432.xyz/static/cc6c487c44b971286c3d0d1e24649619b4681674/getdata.php?chid=',
          afLoginRedirectUrl: '/dashboard',
          newHomePage: false,
          isCustomBanner: false,
          demoLogin: false,
          downloadApk: 'https://application-hub.pages.dev/Saffron77Exch.apk',
          whatsapp: '',
      },
      {
          domain: 'parker777.io',
          name: 'parker777io',
          dName: 'parker777',
          ext: '.io',
          theme: 'blue-body',
          email: '',
          pusher: {
              key: 'c8bbdb36496c73b41567',
              cluster: 'eu'
          },
          themePath: ['assets/css/theme-files/theme-master.css'],
          guestEnable: true,
          ftLivetvData: 'https://e765432.xyz/static/cc6c487c44b971286c3d0d1e24649619b4681674/getdata.php?chid=',
          afLoginRedirectUrl: '/dashboard',
          newHomePage: false,
          isCustomBanner: false,
          demoLogin: false,
          downloadApk: 'https://application-hub.pages.dev/Parker777.apk',
          whatsapp: '',
      },
      {
          domain: 'saffron77exch.games',
          name: 'saffron77exch',
          dName: 'saffron77exch',
          ext: '.games',
          theme: 'blue-body',
          email: '',
          pusher: {
              key: 'c8bbdb36496c73b41567',
              cluster: 'eu'
          },
          themePath: ['assets/css/theme-files/theme-saffron77exch.css'],
          guestEnable: true,
          ftLivetvData: 'https://e765432.xyz/static/cc6c487c44b971286c3d0d1e24649619b4681674/getdata.php?chid=',
          afLoginRedirectUrl: '/dashboard',
          newHomePage: false,
          isCustomBanner: false,
          demoLogin: false,
          downloadApk: 'https://application-hub.pages.dev/Saffron77Exch.apk',
          whatsapp: '',
      },
      {
          domain: 'betfast.games',
          name: 'betfast',
          dName: 'betfast',
          ext: '.games',
          theme: 'blue-body',
          email: '',
          pusher: {
              key: 'c8bbdb36496c73b41567',
              cluster: 'eu'
          },
          themePath: ['assets/css/theme-files/theme-betfast.css'],
          guestEnable: true,
          ftLivetvData: 'https://e765432.xyz/static/cc6c487c44b971286c3d0d1e24649619b4681674/getdata.php?chid=',
          afLoginRedirectUrl: '/dashboard',
          newHomePage: false,
          isCustomBanner: false,
          demoLogin: false,
          downloadApk: 'https://application-hub.pages.dev/BetFast.apk',
          whatsapp: '',
      },
      {
          domain: 'sportsbar11.games',
          name: 'sportsbar11',
          dName: 'sportsbar11',
          ext: '.games',
          theme: 'blue-body',
          email: '',
          pusher: {
              key: 'c8bbdb36496c73b41567',
              cluster: 'eu'
          },
          themePath: ['assets/css/theme-files/theme-sportsbar11.css'],
          guestEnable: true,
          ftLivetvData: 'https://e765432.xyz/static/cc6c487c44b971286c3d0d1e24649619b4681674/getdata.php?chid=',
          afLoginRedirectUrl: '/dashboard',
          newHomePage: false,
          isCustomBanner: false,
          demoLogin: false,
          downloadApk: 'https://application-hub.pages.dev/SportsBar11.apk',
          whatsapp: '',
      },
      {
          domain: 'rolex9.games',
          name: 'rolex9',
          dName: 'rolex9',
          ext: '.games',
          theme: 'blue-body',
          email: '',
          pusher: {
              key: 'c8bbdb36496c73b41567',
              cluster: 'eu'
          },
          themePath: ['assets/css/theme-files/theme-rolex9.css'],
          guestEnable: true,
          ftLivetvData: 'https://e765432.xyz/static/cc6c487c44b971286c3d0d1e24649619b4681674/getdata.php?chid=',
          afLoginRedirectUrl: '/dashboard',
          newHomePage: false,
          isCustomBanner: false,
          demoLogin: false,
          downloadApk: 'https://application-hub.pages.dev/Rolex9.apk',
          whatsapp: '',
      },
      {
          domain: 'tiger9exch.in',
          name: 'tiger9exch',
          dName: 'tiger9exch',
          ext: '.in',
          theme: 'blue-body',
          email: '',
          pusher: {
              key: 'c8bbdb36496c73b41567',
              cluster: 'eu'
          },
          themePath: ['assets/css/theme-files/theme-tiger9exch.css'],
          guestEnable: true,
          ftLivetvData: 'https://e765432.xyz/static/cc6c487c44b971286c3d0d1e24649619b4681674/getdata.php?chid=',
          afLoginRedirectUrl: '/dashboard',
          newHomePage: false,
          isCustomBanner: false,
          demoLogin: false,
          downloadApk: 'https://application-hub.pages.dev/Tiger9Exch.apk',
          whatsapp: '',
      },
      {
          domain: 'starparker.in',
          name: 'starparker',
          dName: 'starparker',
          ext: '.in',
          theme: 'blue-body',
          email: '',
          pusher: {
              key: 'c8bbdb36496c73b41567',
              cluster: 'eu'
          },
          themePath: ['assets/css/theme-files/theme-starparker.css'],
          guestEnable: true,
          ftLivetvData: 'https://e765432.xyz/static/cc6c487c44b971286c3d0d1e24649619b4681674/getdata.php?chid=',
          afLoginRedirectUrl: '/dashboard',
          newHomePage: false,
          isCustomBanner: false,
          demoLogin: false,
          downloadApk: 'https://application-hub.pages.dev/StarParker.apk',
          whatsapp: '',
      },
      {
          domain: 'play11.co',
          name: 'play11',
          dName: 'play11',
          ext: '.co',
          theme: 'blue-body',
          email: '',
          pusher: {
              key: 'c8bbdb36496c73b41567',
              cluster: 'eu'
          },
          themePath: ['assets/css/theme-files/theme-play11.css'],
          guestEnable: true,
          ftLivetvData: 'https://e765432.xyz/static/cc6c487c44b971286c3d0d1e24649619b4681674/getdata.php?chid=',
          afLoginRedirectUrl: '/dashboard',
          newHomePage: false,
          isCustomBanner: false,
          demoLogin: false,
          downloadApk: 'https://application-hub.pages.dev/Play11.apk',
          whatsapp: '',
      },
      {
          domain: 'mahakal9.in',
          name: 'mahakal9',
          dName: 'mahakal9',
          ext: '.in',
          theme: 'blue-body',
          email: '',
          pusher: {
              key: 'c8bbdb36496c73b41567',
              cluster: 'eu'
          },
          themePath: ['assets/css/theme-files/theme-mahakal9.css'],
          guestEnable: true,
          ftLivetvData: 'https://e765432.xyz/static/cc6c487c44b971286c3d0d1e24649619b4681674/getdata.php?chid=',
          afLoginRedirectUrl: '/dashboard',
          newHomePage: false,
          isCustomBanner: false,
          demoLogin: false,
          downloadApk: 'https://application-hub.pages.dev/Mahakal9.apk',
          whatsapp: '',
      },
      {
          domain: 'empireexch.in',
          name: 'empireexch',
          dName: 'empireexch',
          ext: '.in',
          theme: 'blue-body',
          email: '',
          pusher: {
              key: 'c8bbdb36496c73b41567',
              cluster: 'eu'
          },
          themePath: ['assets/css/theme-files/theme-empireexch.css'],
          guestEnable: true,
          ftLivetvData: 'https://e765432.xyz/static/cc6c487c44b971286c3d0d1e24649619b4681674/getdata.php?chid=',
          afLoginRedirectUrl: '/dashboard',
          newHomePage: false,
          isCustomBanner: false,
          demoLogin: false,
          downloadApk: 'https://application-hub.pages.dev/EmpireExch.apk',
          whatsapp: '',
      },
      {
          domain: 'balajiexch99.in',
          name: 'balajiexch99',
          dName: 'balajiexch99',
          ext: '.in',
          theme: 'blue-body',
          email: '',
          pusher: {
              key: 'c8bbdb36496c73b41567',
              cluster: 'eu'
          },
          themePath: ['assets/css/theme-files/theme-balajiexch99.css'],
          guestEnable: true,
          ftLivetvData: 'https://e765432.xyz/static/cc6c487c44b971286c3d0d1e24649619b4681674/getdata.php?chid=',
          afLoginRedirectUrl: '/dashboard',
          newHomePage: false,
          isCustomBanner: false,
          demoLogin: false,
          downloadApk: 'https://application-hub.pages.dev/BalajiExch99.apk',
          whatsapp: '',
      },
      {
          domain: 'balajionline777.com',
          name: 'balajionline777',
          dName: 'balajionline777',
          ext: '.com',
          theme: 'blue-body',
          email: '',
          pusher: {
              key: 'c8bbdb36496c73b41567',
              cluster: 'eu'
          },
          themePath: ['assets/css/theme-files/theme-balajionline777.css'],
          guestEnable: true,
          ftLivetvData: 'https://e765432.xyz/static/cc6c487c44b971286c3d0d1e24649619b4681674/getdata.php?chid=',
          afLoginRedirectUrl: '/dashboard',
          newHomePage: false,
          isCustomBanner: false,
          demoLogin: false,
          downloadApk: 'https://application-hub.pages.dev/BalajiOnline777.apk',
          whatsapp: '',
      },
      {
          domain: 'jannatexch999.in',
          name: 'jannatexch999',
          dName: 'jannatexch999',
          ext: '.in',
          theme: 'blue-body',
          email: '',
          pusher: {
              key: 'c8bbdb36496c73b41567',
              cluster: 'eu'
          },
          themePath: ['assets/css/theme-files/theme-jannatexch999.css'],
          guestEnable: true,
          ftLivetvData: 'https://e765432.xyz/static/cc6c487c44b971286c3d0d1e24649619b4681674/getdata.php?chid=',
          afLoginRedirectUrl: '/dashboard',
          newHomePage: false,
          isCustomBanner: false,
          demoLogin: false,
          downloadApk: 'https://application-hub.pages.dev/JannatExch999.apk',
          whatsapp: '',
      },
      {
          domain: 'bigbet99exch.com',
          name: 'bigbet99exch',
          dName: 'bigbet99exch',
          ext: '.com',
          theme: 'blue-body',
          email: '',
          pusher: {
              key: 'c8bbdb36496c73b41567',
              cluster: 'eu'
          },
          themePath: ['assets/css/theme-files/theme-bigbet99exch.css'],
          guestEnable: true,
          ftLivetvData: 'https://e765432.xyz/static/cc6c487c44b971286c3d0d1e24649619b4681674/getdata.php?chid=',
          afLoginRedirectUrl: '/dashboard',
          newHomePage: false,
          isCustomBanner: false,
          demoLogin: false,
          downloadApk: 'https://application-hub.pages.dev/BigBet99Exch.apk',
          whatsapp: '',
      },
      {
          domain: 'bigbee777.com',
          name: 'bigbee777',
          dName: 'bigbee777',
          ext: '.com',
          theme: 'blue-body',
          email: '',
          pusher: {
              key: 'c8bbdb36496c73b41567',
              cluster: 'eu'
          },
          themePath: ['assets/css/theme-files/theme-bigbee777.css'],
          guestEnable: true,
          ftLivetvData: 'https://e765432.xyz/static/cc6c487c44b971286c3d0d1e24649619b4681674/getdata.php?chid=',
          afLoginRedirectUrl: '/dashboard',
          newHomePage: false,
          isCustomBanner: false,
          demoLogin: false,
          downloadApk: 'https://application-hub.pages.dev/BigBee777.apk',
          whatsapp: '',
      },
      {
          domain: 'garuda555.co',
          name: 'garuda555',
          dName: 'garuda555',
          ext: '.co',
          theme: 'blue-body',
          email: '',
          pusher: {
              key: 'c8bbdb36496c73b41567',
              cluster: 'eu'
          },
          themePath: ['assets/css/theme-files/theme-garuda555.css'],
          guestEnable: true,
          ftLivetvData: 'https://e765432.xyz/static/cc6c487c44b971286c3d0d1e24649619b4681674/getdata.php?chid=',
          afLoginRedirectUrl: '/dashboard',
          newHomePage: false,
          isCustomBanner: false,
          demoLogin: false,
          downloadApk: 'https://application-hub.pages.dev/Garuda555.apk',
          whatsapp: '',
      },
      {
          domain: 'fbcexch.com',
          name: 'fbcexch',
          dName: 'fbcexch',
          ext: '.com',
          theme: 'blue-body',
          email: '',
          pusher: {
              key: 'c8bbdb36496c73b41567',
              cluster: 'eu'
          },
          themePath: ['assets/css/theme-files/theme-fbc365exch.css'],
          guestEnable: true,
          ftLivetvData: 'https://e765432.xyz/static/cc6c487c44b971286c3d0d1e24649619b4681674/getdata.php?chid=',
          afLoginRedirectUrl: '/dashboard',
          newHomePage: false,
          isCustomBanner: false,
          demoLogin: false,
          downloadApk: 'https://application-hub.pages.dev/Fbc365Exch.apk',
          whatsapp: '',
      },
      {
          domain: 'gamefair247.com',
          name: 'gamefair247',
          dName: 'gamefair247',
          ext: '.com',
          theme: 'blue-body',
          email: '',
          pusher: {
              key: 'c8bbdb36496c73b41567',
              cluster: 'eu'
          },
          themePath: ['assets/css/theme-files/theme-gamefair.css'],
          guestEnable: true,
          ftLivetvData: 'https://e765432.xyz/static/cc6c487c44b971286c3d0d1e24649619b4681674/getdata.php?chid=',
          afLoginRedirectUrl: '/dashboard',
          newHomePage: false,
          isCustomBanner: false,
          demoLogin: false,
          downloadApk: 'https://application-hub.pages.dev/GameFair247.apk',
          whatsapp: '',
      },
      {
          domain: 'playnwin555.com',
          name: 'playnwin555',
          dName: 'playnwin555',
          ext: '.com',
          theme: 'blue-body',
          email: '',
          pusher: {
              key: 'c8bbdb36496c73b41567',
              cluster: 'eu'
          },
          themePath: ['assets/css/theme-files/theme-playwin555.css'],
          guestEnable: true,
          ftLivetvData: 'https://e765432.xyz/static/cc6c487c44b971286c3d0d1e24649619b4681674/getdata.php?chid=',
          afLoginRedirectUrl: '/dashboard',
          newHomePage: false,
          isCustomBanner: false,
          demoLogin: false,
          downloadApk: 'https://application-hub.pages.dev/PlayNWin555.apk',
          whatsapp: '',
      },
      {
          domain: 'play365exch.in',
          name: 'play365exch',
          dName: 'play365exch',
          ext: '.in',
          theme: 'blue-body',
          email: '',
          pusher: {
              key: 'c8bbdb36496c73b41567',
              cluster: 'eu'
          },
          themePath: ['assets/css/theme-files/theme-play365exch.css'],
          guestEnable: true,
          ftLivetvData: 'https://e765432.xyz/static/cc6c487c44b971286c3d0d1e24649619b4681674/getdata.php?chid=',
          afLoginRedirectUrl: '/dashboard',
          newHomePage: false,
          isCustomBanner: false,
          demoLogin: false,
          downloadApk: 'https://application-hub.pages.dev/Play365Exch.apk',
          whatsapp: '',
      },
      {
          domain: 'phoenixexch.in',
          name: 'phoenixexch',
          dName: 'phoenixexch',
          ext: '.in',
          theme: 'blue-body',
          email: '',
          pusher: {
              key: 'c8bbdb36496c73b41567',
              cluster: 'eu'
          },
          themePath: ['assets/css/theme-files/theme-phoenixexch.css'],
          guestEnable: true,
          ftLivetvData: 'https://e765432.xyz/static/cc6c487c44b971286c3d0d1e24649619b4681674/getdata.php?chid=',
          afLoginRedirectUrl: '/dashboard',
          newHomePage: false,
          isCustomBanner: false,
          demoLogin: false,
          downloadApk: 'https://application-hub.pages.dev/PhoenixExch.apk',
          whatsapp: '',
      },
      {
          domain: 'lucky99exch.com',
          name: 'lucky99exch',
          dName: 'lucky99exch',
          ext: '.com',
          theme: 'blue-body',
          email: '',
          pusher: {
              key: 'c8bbdb36496c73b41567',
              cluster: 'eu'
          },
          themePath: ['assets/css/theme-files/theme-master.css'],
          guestEnable: true,
          ftLivetvData: 'https://e765432.xyz/static/cc6c487c44b971286c3d0d1e24649619b4681674/getdata.php?chid=',
          afLoginRedirectUrl: '/dashboard',
          newHomePage: false,
          isCustomBanner: false,
          demoLogin: false,
          downloadApk: 'https://application-hub.pages.dev/Lucky99Exch.apk',
          whatsapp: '',
      },
      {
          domain: 'khelahobe.org',
          name: 'khelahobe',
          dName: 'khelahobe',
          ext: '.org',
          theme: 'blue-body',
          email: '',
          pusher: {
              key: 'c8bbdb36496c73b41567',
              cluster: 'eu'
          },
          themePath: ['assets/css/theme-files/theme-khelahobe.css'],
          guestEnable: true,
          ftLivetvData: 'https://e765432.xyz/static/cc6c487c44b971286c3d0d1e24649619b4681674/getdata.php?chid=',
          afLoginRedirectUrl: '/dashboard',
          newHomePage: false,
          isCustomBanner: false,
          demoLogin: false,
          downloadApk: '',
          whatsapp: '',
      },
      {
          domain: 'lotusbook777.co',
          name: 'lotusbook777',
          dName: 'lotusbook777',
          ext: '.co',
          theme: 'blue-body',
          email: '',
          pusher: {
              key: 'c8bbdb36496c73b41567',
              cluster: 'eu'
          },
          themePath: ['assets/css/theme-files/theme-lotusbook777.css'],
          guestEnable: true,
          ftLivetvData: 'https://e765432.xyz/static/cc6c487c44b971286c3d0d1e24649619b4681674/getdata.php?chid=',
          afLoginRedirectUrl: '/dashboard',
          newHomePage: false,
          isCustomBanner: false,
          demoLogin: false,
          downloadApk: '',
          whatsapp: '',
      },
      {
          domain: 'dreamexch99.com',
          name: 'dreamexch99',
          dName: 'dreamexch99',
          ext: '.com',
          theme: 'blue-body',
          email: '',
          pusher: {
              key: 'c8bbdb36496c73b41567',
              cluster: 'eu'
          },
          themePath: ['assets/css/theme-files/theme-dreamexch99.css'],
          guestEnable: true,
          ftLivetvData: 'https://e765432.xyz/static/cc6c487c44b971286c3d0d1e24649619b4681674/getdata.php?chid=',
          afLoginRedirectUrl: '/dashboard',
          newHomePage: false,
          isCustomBanner: false,
          demoLogin: false,
          downloadApk: '',
          whatsapp: '',
      },
      {
          domain: 'spbook88.com',
          name: 'spbook88',
          dName: 'spbook88',
          ext: '.com',
          theme: 'blue-body',
          email: '',
          pusher: {
              key: 'c8bbdb36496c73b41567',
              cluster: 'eu'
          },
          themePath: ['assets/css/theme-files/theme-spbook.css'],
          guestEnable: true,
          ftLivetvData: 'https://e765432.xyz/static/cc6c487c44b971286c3d0d1e24649619b4681674/getdata.php?chid=',
          afLoginRedirectUrl: '/dashboard',
          newHomePage: false,
          isCustomBanner: false,
          demoLogin: false,
          downloadApk: '',
          whatsapp: '',
      },
      {
          domain: 'maharaja999.in',
          name: 'maharaja999',
          dName: 'maharaja999',
          ext: '.in',
          theme: 'blue-body',
          email: '',
          pusher: {
              key: 'c8bbdb36496c73b41567',
              cluster: 'eu'
          },
          themePath: ['assets/css/theme-files/theme-maharaja999.css'],
          guestEnable: true,
          ftLivetvData: 'https://e765432.xyz/static/cc6c487c44b971286c3d0d1e24649619b4681674/getdata.php?chid=',
          afLoginRedirectUrl: '/dashboard',
          newHomePage: false,
          isCustomBanner: false,
          demoLogin: false,
          downloadApk: '',
          whatsapp: '',
      },
      {
          domain: 'kapildev365.com',
          name: 'kapildev365',
          dName: 'kapildev365',
          ext: '.com',
          theme: 'blue-body',
          email: '',
          pusher: {
              key: 'c8bbdb36496c73b41567',
              cluster: 'eu'
          },
          themePath: ['assets/css/theme-files/theme-kapildev365.css'],
          guestEnable: true,
          ftLivetvData: 'https://e765432.xyz/static/cc6c487c44b971286c3d0d1e24649619b4681674/getdata.php?chid=',
          afLoginRedirectUrl: '/dashboard',
          newHomePage: false,
          isCustomBanner: false,
          demoLogin: false,
          downloadApk: '',
          whatsapp: '',
      },
      {
          domain: 'mjexch.com',
          name: 'mjexch',
          dName: 'mjexch',
          ext: '.com',
          theme: 'blue-body',
          email: '',
          pusher: {
              key: 'c8bbdb36496c73b41567',
              cluster: 'eu'
          },
          themePath: ['assets/css/theme-files/theme-mjexch.css'],
          guestEnable: true,
          ftLivetvData: 'https://e765432.xyz/static/cc6c487c44b971286c3d0d1e24649619b4681674/getdata.php?chid=',
          afLoginRedirectUrl: '/dashboard',
          newHomePage: false,
          isCustomBanner: false,
          demoLogin: false,
          downloadApk: '',
          whatsapp: '',
      },
      {
          domain: 'win777exch.in',
          name: 'win777exch',
          dName: 'win777exch',
          ext: '.in',
          theme: 'blue-body',
          email: '',
          pusher: {
              key: 'c8bbdb36496c73b41567',
              cluster: 'eu'
          },
          themePath: ['assets/css/theme-files/theme-win777exch.css'],
          guestEnable: true,
          ftLivetvData: 'https://e765432.xyz/static/cc6c487c44b971286c3d0d1e24649619b4681674/getdata.php?chid=',
          afLoginRedirectUrl: '/dashboard',
          newHomePage: false,
          isCustomBanner: false,
          demoLogin: false,
          downloadApk: '',
          whatsapp: '',
      },
      {
          domain: 'kingsmakers.in',
          name: 'kingsmakers',
          dName: 'kingsmakers',
          ext: '.in',
          theme: 'blue-body',
          email: '',
          pusher: {
              key: 'c8bbdb36496c73b41567',
              cluster: 'eu'
          },
          themePath: ['assets/css/theme-files/theme-kingsmaker.css'],
          guestEnable: true,
          ftLivetvData: 'https://e765432.xyz/static/cc6c487c44b971286c3d0d1e24649619b4681674/getdata.php?chid=',
          afLoginRedirectUrl: '/dashboard',
          newHomePage: false,
          isCustomBanner: false,
          demoLogin: false,
          downloadApk: '',
          whatsapp: '',
      },
      {
          domain: 'redsports999.in',
          name: 'redsports999',
          dName: 'redsports999',
          ext: '.in',
          theme: 'blue-body',
          email: '',
          pusher: {
              key: 'c8bbdb36496c73b41567',
              cluster: 'eu'
          },
          themePath: ['assets/css/theme-files/theme-maharaja999.css'],
          guestEnable: true,
          ftLivetvData: 'https://e765432.xyz/static/cc6c487c44b971286c3d0d1e24649619b4681674/getdata.php?chid=',
          afLoginRedirectUrl: '/dashboard',
          newHomePage: false,
          isCustomBanner: false,
          demoLogin: false,
          downloadApk: '',
          whatsapp: '',
      },
      {
          domain: 'zebraexch7.com',
          name: 'zebraexch7',
          dName: 'zebraexch7',
          ext: '.com',
          theme: 'blue-body',
          email: '',
          pusher: {
              key: 'c8bbdb36496c73b41567',
              cluster: 'eu'
          },
          themePath: ['assets/css/theme-files/theme-zebraexch7.css'],
          guestEnable: true,
          ftLivetvData: 'https://e765432.xyz/static/cc6c487c44b971286c3d0d1e24649619b4681674/getdata.php?chid=',
          afLoginRedirectUrl: '/dashboard',
          newHomePage: false,
          isCustomBanner: false,
          demoLogin: false,
          downloadApk: '',
          whatsapp: '',
      },
      {
          domain: 'pridexexch777.com',
          name: 'pridexexch777',
          dName: 'pridexexch777',
          ext: '.com',
          theme: 'blue-body',
          email: '',
          pusher: {
              key: 'c8bbdb36496c73b41567',
              cluster: 'eu'
          },
          themePath: ['assets/css/theme-files/theme-pride.css'],
          guestEnable: true,
          ftLivetvData: 'https://e765432.xyz/static/cc6c487c44b971286c3d0d1e24649619b4681674/getdata.php?chid=',
          afLoginRedirectUrl: '/dashboard',
          newHomePage: false,
          isCustomBanner: false,
          demoLogin: false,
          downloadApk: '',
          whatsapp: '',
      },
      {
          domain: 'whitepawn777.in',
          name: 'whitepawn777',
          dName: 'whitepawn777',
          ext: '.in',
          theme: 'blue-body',
          email: '',
          pusher: {
              key: 'c8bbdb36496c73b41567',
              cluster: 'eu'
          },
          themePath: ['assets/css/theme-files/theme-whitepawn.css'],
          guestEnable: true,
          ftLivetvData: 'https://e765432.xyz/static/cc6c487c44b971286c3d0d1e24649619b4681674/getdata.php?chid=',
          afLoginRedirectUrl: '/dashboard',
          newHomePage: false,
          isCustomBanner: false,
          demoLogin: false,
          downloadApk: '',
          whatsapp: '',
      },
      {
        domain: 'spexchange.club',
        name: 'spexchange',
        dName: 'spexchange',
        ext: '.club',
        theme: 'blue-body',
        email: '',
        pusher: {
            key: 'c8bbdb36496c73b41567',
            cluster: 'eu'
        },
        themePath: ['assets/css/theme-files/theme-master.css'],
        guestEnable: true,
        ftLivetvData: 'https://e765432.xyz/static/cc6c487c44b971286c3d0d1e24649619b4681674/getdata.php?chid=',
        afLoginRedirectUrl: '/dashboard',
        newHomePage: false,
        isCustomBanner: false,
        demoLogin: false,
        downloadApk: '',
        whatsapp: '',
    },
    {
        domain: 'noh555.com',
        name: 'noh555',
        dName: 'noh555',
        ext: '.club',
        theme: 'blue-body',
        email: '',
        pusher: {
            key: 'c8bbdb36496c73b41567',
            cluster: 'eu'
        },
        themePath: ['assets/css/theme-files/theme-master.css'],
        guestEnable: true,
        ftLivetvData: 'https://e765432.xyz/static/cc6c487c44b971286c3d0d1e24649619b4681674/getdata.php?chid=',
        afLoginRedirectUrl: '/dashboard',
        newHomePage: false,
        isCustomBanner: false,
        demoLogin: false,
        downloadApk: '',
        whatsapp: '',
    },
    {
        domain: 'shyam77.com',
        name: 'shyam77',
        dName: 'shyam77',
        ext: '.com',
        theme: 'blue-body',
        email: '',
        pusher: {
            key: 'c8bbdb36496c73b41567',
            cluster: 'eu'
        },
        themePath: ['assets/css/theme-files/theme-balajionline777.css'],
        guestEnable: true,
        ftLivetvData: 'https://e765432.xyz/static/cc6c487c44b971286c3d0d1e24649619b4681674/getdata.php?chid=',
        afLoginRedirectUrl: '/dashboard',
        newHomePage: false,
        isCustomBanner: false,
        demoLogin: false,
        downloadApk: '',
        whatsapp: '',
    },
    {
        domain: 'dksports555.in',
        name: 'dksports555',
        dName: 'dksports555',
        ext: '.in',
        theme: 'blue-body',
        email: '',
        pusher: {
            key: 'c8bbdb36496c73b41567',
            cluster: 'eu'
        },
        themePath: ['assets/css/theme-files/theme-maharaja999.css'],
        guestEnable: true,
        ftLivetvData: 'https://e765432.xyz/static/cc6c487c44b971286c3d0d1e24649619b4681674/getdata.php?chid=',
        afLoginRedirectUrl: '/dashboard',
        newHomePage: false,
        isCustomBanner: false,
        demoLogin: false,
        downloadApk: '',
        whatsapp: '',
    },
  ]
};

